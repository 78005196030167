<template>
  <b-img
    v-if="hasExpiredCards || hasExpiredSoonCards"
    :src="hasExpiredCards ? expiredSrc : expiredSoonSrc"
  />
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'NavAccountSettingsAlertIcon',
  data() {
    return {
      expiredSrc: '/images/payments/nav-alert-triangle.svg',
      expiredSoonSrc: '/images/payments/nav-warning-triangle.svg',
    }
  },
  computed: {
    ...mapGetters('paymentMethods', [
      'hasExpiredCards',
      'hasExpiredSoonCards',
    ]),
  },
}
</script>
