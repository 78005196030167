<template>
  <b-modal
    id="items-requiring-attention-modal"
    ref="items-requiring-attention-modal"
    centered
    size="lg"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <template slot="modal-title">
      <div class="cart-title card-title mt-0">
        Checkout

        <div v-if="company" style="display:inline-block">
          |
          <span v-if="company" class="company-name">
            {{ company.name }}
          </span>
        </div>
      </div>
    </template>


    <post-payment-items-requiring-attention
      v-if="invoiceId"
      :company-id="company.id"
      :invoice-id="invoiceId"
      @completed="completed"
    />
  </b-modal>
</template>


<script>

import { mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'ItemsRequiringAttentionModal',

  components: {
    PostPaymentItemsRequiringAttention: () => import('@/components/HireUs/PostPaymentItemsRequiringAttention'),
  },

  mixins: [makeToastMixin],

  props: {
    invoiceId: {
      type: String,
    },
  },

  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
  },

  methods: {
    close() {
      this.$refs['items-requiring-attention-modal'].hide()
    },
    show() {
      this.$refs['items-requiring-attention-modal'].show()
    },
    completed() {
      this.close()
      this.successToast('Success', 'Submitted.')
    },
  },
}
</script>


<style scoped lang="scss">
  .company-name {
    margin: 0;
    font-size: .75em;
    color: lightgray;
  }
</style>
