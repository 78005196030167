var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.cartEmpty
    ? _c(
        "b-modal",
        {
          ref: "checkout-modal",
          attrs: {
            id: "checkout-modal",
            centered: "",
            size: "lg",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "modal-title",
                fn: function () {
                  return [
                    _c("div", { staticClass: "cart-title card-title mt-0" }, [
                      _vm._v("\n      Checkout\n\n      "),
                      _vm.company
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _vm._v("\n        |\n        "),
                              _vm.company
                                ? _c("span", { staticClass: "company-name" }, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.company.name) +
                                        "\n        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2633963560
          ),
        },
        [
          _c("checkout-cart-body", {
            scopedSlots: _vm._u(
              [
                {
                  key: "cart-header",
                  fn: function () {
                    return [
                      _c("h5", { staticClass: "card-header-text pt-3" }, [
                        _vm._v("\n        Order Summary\n      "),
                      ]),
                      _c("hr"),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1444494402
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }