var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    {
      staticClass: "nav-feed-dropdown-component",
      attrs: { type: "dark", variant: "dark" },
    },
    [
      _c(
        "b-nav-item-dropdown",
        { attrs: { right: "" } },
        [
          _c("template", { slot: "button-content" }, [
            _c("div", { staticStyle: { float: "left" } }, [
              _c("div", { staticClass: "nav-icon-label" }, [
                _c(
                  "div",
                  { staticClass: "feed-items-icon" },
                  [
                    _c("b-img", {
                      attrs: { src: "/images/navFeed/rssFeed.svg" },
                    }),
                    _vm.feedUnreadCount > 0
                      ? _c(
                          "div",
                          { ref: "feed-count", staticClass: "feed-count" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.feedUnreadCount) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("nav-feed"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }