var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-feed-container" }, [
    _c(
      "div",
      { staticClass: "nav-feed-header-container" },
      [
        _c("header", [_vm._v("News Feed")]),
        _c(
          "b-button",
          {
            staticClass: "header-button",
            attrs: { variant: "link" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.markAllAsRead()
              },
            },
          },
          [_vm._v("Mark all as read")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "nav-feed-card-container" }, [
      _vm.feedNotifications.length > 0
        ? _c(
            "div",
            _vm._l(_vm.feedNotifications, function (notification, index) {
              return _c(
                "b-card",
                {
                  key: index,
                  class: [
                    "hoverable-card",
                    "nav-feed-card-content",
                    _vm.notificationStyle(
                      notification.type,
                      notification.status
                    ),
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.handleCardClick(notification.link)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "nav-feed-card-top" },
                    [
                      _vm.hasImage(notification.image_name)
                        ? _c("b-img", {
                            staticStyle: { "padding-top": ".25rem" },
                            attrs: {
                              src: `/images/navFeed/${_vm.validImage(
                                notification.image_name
                              )}.svg`,
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "nav-feed-card-top-content" }, [
                        _c(
                          "div",
                          { staticClass: "nav-feed-card-title-content" },
                          [_c("header", [_vm._v(_vm._s(notification.title))])]
                        ),
                        _c("div", { staticClass: "nav-feed-card-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(notification.text) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "nav-feed-card-bottom" }, [
                    notification.status !== "read"
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mark-as-button",
                                attrs: { variant: "link" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.updateStatus(
                                      notification.accounts_feed_notification_id,
                                      "read"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Mark as read\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mark-as-button--alt",
                                attrs: { variant: "link" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.updateStatus(
                                      notification.accounts_feed_notification_id,
                                      "unread"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Mark as unread\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                    _c("div", { staticClass: "nav-feed-time-since" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(notification.time_since) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-card",
                {
                  class: [
                    "nav-feed-card-content",
                    _vm.notificationStyle("none"),
                  ],
                },
                [
                  _c("div", { staticClass: "nav-feed-card-text" }, [
                    _c("em", [_vm._v("No new notifications")]),
                  ]),
                ]
              ),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }