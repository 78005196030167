import { mapActions, mapGetters } from 'vuex'
import { logNavigationMixin } from '@/mixins/logNavigationMixin'

export const faqMixin = {
  mixins: [logNavigationMixin],
  data() {
    return {
      categoryRoutes: new Map([
        ['Documents', 'documents'],
        ['Forms Library', 'companySelection'],
        ['Registrations Tab', 'registrations'],
        ['Vehicles', 'vehicleRegistrations'],
      ]),
    }
  },
  computed: {
    ...mapGetters('account', ['account']),
    ...mapGetters('faq', ['faqInformation']),
  },
  methods: {
    ...mapActions('faq', ['fetchFaqInformation']),
    logFaqPageNavigation(interaction) {
      this.logNavigation({ name: 'faq' }, 'faq-page', 'landing-page', interaction)
    },
    logFaqAssociatedPageNavigation(categoryId, pageId, interaction) {
      this.logNavigation({ name: 'faqPage', params: { categoryId: categoryId, pageId: pageId }}, 'faq-associated-page', pageId, interaction)
    },
    logFaqCategoryNavigation(categoryId, interaction, otherPageRoute = null) {
      this.createAndCompleteLog('faq-category', categoryId, interaction)
      if (!otherPageRoute) { // navigating to FAQ category page
        this.$router.push({ name: 'categoryId', params: { categoryId: categoryId }})
      } else { // navigating away from FAQ category page
        this.$router.push({ name: otherPageRoute })
      }
    },
  },
}
