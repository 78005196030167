<template>
  <b-navbar-nav
    class="ml-auto w-100 law-on-call-dropdown-component"
    type="dark"
    variant="dark"
    right
  >
    <ct-top-nav-item
      to="/law-on-call"
      :disabled="activeLawOnCallServices.length < 1"
      class="law-on-call-nav-item"
      :class="{
        active: $route.path.indexOf('law-on-call') > -1,
        disabled: activeLawOnCallServices.length < 1,
      }"
    >
      <template slot="display-html">
        <div class="nav-logo-container">
          <picture>
            <source
              :srcset="require('@images/LawOnCall_logo_fi_icon.webp')"
              type="image/webp"
            >
            <img
              :src="require('@images/LawOnCall_logo_fi_icon.png')"
              alt="Law On Call"
              class="logo"
            >
          </picture>
          Law On<br>
          Call
        </div>
      </template>
    </ct-top-nav-item>
    <ct-top-nav-item
      dropdown
      align-right
      class="law-on-call-nav-dropdown"
      :class="{ active: $route.path.indexOf('law-on-call') > -1 }"
    >
      <template
        v-if="activeLawOnCallServices.length > 0"
        slot="dropdown-content"
      >
        <b-dropdown-text>
          <div class="dropdown-container mb-2">
            <div class="logo-container">
              <picture>
                <source
                  :srcset="require('@images/LawOnCall_logo_fi.webp')"
                  type="image/webp"
                >
                <img
                  :src="require('@images/LawOnCall_logo_fi.png')"
                  alt="Law On Call"
                  class="logo"
                >
              </picture>
            </div>

            <hr>

            <p class="mb-4" style="text-align: left; font-size: .8em; ">
              You have active Law On Call service. For legal advice for your jurisdiction, reach out to our legal team at one of the numbers below.
            </p>

            <hr>

            <div v-for="service in activeLawOnCallServices" :key="service.id" class="active-law-on-call-service">
              <div class="state-province-region">
                {{ service.stateProvinceRegion }}
              </div>
              <div class="phone-number">
                {{ service.phoneNumber }}
              </div>
            </div>
          </div>
        </b-dropdown-text>
      </template>

      <template
        v-else
        slot="dropdown-content"
      >
        <b-dropdown-text>
          <div class="dropdown-container mb-2 sign-up">
            <div class="logo-container">
              <picture>
                <source
                  :srcset="require('@images/LawOnCall_logo_fi.webp')"
                  type="image/webp"
                >
                <img
                  :src="require('@images/LawOnCall_logo_fi.png')"
                  alt="Law On Call"
                  class="logo"
                >
              </picture>
            </div>

            <hr>

            <div class="mb-4" style="text-align: center; font-size: .8em; ">
              <p class="need-advice">
                Looking for legal advice?
              </p>
              With our Law On Call service, a lawyer is just a phone call away.
            </div>

            <b-button
              to="/hire-us"
              variant="primary"
              class="btn btn-primary"
            >
              Sign Up for $9/mo
            </b-button>
          </div>
        </b-dropdown-text>
      </template>
    </ct-top-nav-item>
  </b-navbar-nav>
</template>

<script>

import { mapGetters } from 'vuex'
import CtTopNavItem from "@/components/shared/CtTopNavItem"

export default {
  name: 'LawOnCallDropdown',
  components: {
    CtTopNavItem,
  },
  computed: {
    ...mapGetters('dashpanel', [
      'activeLawOnCallServices',
    ]),
  },
}
</script>

<style lang="scss">
.law-on-call-dropdown-component {
  height: 100%;

  .dropdown-container {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0 22px 0;
    picture {
      .logo {
        width: 100%;
      }
    }
  }
  .nav-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    picture {
      .logo {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
  .law-on-call-nav-item {
    border-left: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &.disabled {
      pointer-events: none;
    }
  }
  .law-on-call-nav-dropdown {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 30px;
    margin-right: 10px;
    .dropdown-menu.show {
      min-width: 260px;
      left: -230px;
    }
    .dropdown-item {
      padding: 0;
    }
    .dropdown-toggle {
      position: relative;
      right: 10px;
      bottom: 4px;
    }
    .b-dropdown-text {
      margin: 0 0 10px 0;
      .active-law-on-call-service {
        margin: 20px auto 0 auto;
        width: max-content;
        display: flex;
        flex-direction: column;
        .state-province-region {
          text-transform: uppercase;
          font-size: 16px !important;
          color: $ct-ui-font-color-secondary;
          font-weight: $ct-ui-font-weight-9;
          letter-spacing: 1.2px;
        }
        .phone-number {
          font-size: 1.3em;
          font-weight: 700;
          letter-spacing: 2px;
        }
      }
    }
  }
  .sign-up {
    .law-on-call-title {
      text-transform: uppercase;
      color: $ct-ui-font-color-secondary;
      font-size: 15px;
    }
    .need-advice {
      font-size: 1.1em;
      font-weight: 700;
    }
    .btn {
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

</style>
