var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.unread || _vm.configurationNeededCount
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-row justify-content-start align-items-center",
        },
        [
          _c(
            "div",
            {
              staticClass: "fa-stack",
              staticStyle: { position: "relative", top: "2px", width: "2em" },
            },
            [
              _c("feather-icon", {
                staticClass: "fa-stack-2x mr-3",
                attrs: { type: _vm.iconType },
              }),
              _vm.configurationNeededCount > 0
                ? _c("fa-icon", {
                    staticClass:
                      "fa-stack-1x float-right circle-configuration-needed mr-3 mb-3 pl-1",
                    attrs: { icon: "circle", size: "sm" },
                  })
                : _vm.unread && _vm.configurationNeededCount === 0
                ? _c("fa-icon", {
                    staticClass:
                      "fa-stack-1x float-right circle mr-3 mb-3 pl-1",
                    attrs: { icon: "circle", size: "sm" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("span", [_vm._v("\n    " + _vm._s(_vm.displayText) + "\n  ")]),
        ]
      )
    : _c(
        "div",
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { type: _vm.iconType },
          }),
          _c("span", [_vm._v("\n    " + _vm._s(_vm.displayText) + "\n  ")]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }