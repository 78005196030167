var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { id: "nav-cart-component" } },
    [
      _c("div", { staticClass: "cart-title card-title mt-0" }, [
        _vm._v("\n    Order Summary\n  "),
      ]),
      _vm.isStagelineRoute && _vm.company
        ? [
            _c("h6", { staticClass: "company-name" }, [
              _vm._v("\n      " + _vm._s(_vm.company.name) + "\n    "),
            ]),
          ]
        : _vm._e(),
      _c("hr"),
      _c("b-card-text", { staticClass: "cart-items" }, [
        _vm.cartEmpty
          ? _c("em", [_vm._v("Your cart is empty.")])
          : _c("div", [_c("cart-item-list")], 1),
      ]),
      !_vm.cartEmpty
        ? [
            _c("hr"),
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-between total" },
              [
                _c("div", [_c("strong", [_vm._v("Total")])]),
                _c("div", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm._f("dollars")(_vm.cartItemsTotal))),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      !_vm.hideButton && !_vm.isStagelineRoute && _vm.cartItems.length > 0
        ? _c(
            "b-form-group",
            { staticClass: "cart-button float-right" },
            [
              !_vm.processingCheckout
                ? _c(
                    "b-button",
                    {
                      staticClass: "nav-cart-button",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.rootEmit("cart-next-page")
                        },
                      },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isStagelineNonCheckoutRoute && _vm.cartItems.length > 0
        ? _c(
            "b-form-group",
            { staticClass: "cart-button mt-3" },
            [
              !_vm.processingCheckout
                ? _c(
                    "b-button",
                    {
                      staticClass: "nav-cart-button w-100",
                      attrs: { variant: "primary" },
                      on: { click: _vm.determineCheckoutModal },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.checkoutButtonText) + "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.processingCheckout ? _c("ct-centered-spinner") : _vm._e(),
      _c("collect-account-info", {
        ref: `collectAccountInfoModal-${_vm.thisUid}`,
        on: { success: _vm.determineCheckoutModal },
      }),
      _c("checkout-modal", {
        ref: "checkout-modal",
        on: {
          invoiceHasItemsRequiringAttention:
            _vm.showItemsRequiringAttentionModal,
        },
      }),
      _c("items-requiring-attention-modal", {
        ref: "items-requiring-attention-modal",
        attrs: { "invoice-id": _vm.invoiceId },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }