var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dropdown
    ? _c(
        "b-nav-item-dropdown",
        _vm._b(
          { staticClass: "ct-top-nav-item" },
          "b-nav-item-dropdown",
          _vm.$attrs,
          false
        ),
        [
          _c("template", { slot: "button-content" }, [
            _c(
              "div",
              { staticClass: "nav-count-container" },
              [
                _vm._t("display-html"),
                _vm.unreadCount > 0
                  ? _c(
                      "span",
                      { ref: "unread-count", staticClass: "unread-count" },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.unreadCount) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._t("dropdown-content"),
        ],
        2
      )
    : _c(
        "b-nav-item",
        _vm._b(
          { staticClass: "ct-top-nav-item" },
          "b-nav-item",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "div",
            { staticClass: "nav-count-container" },
            [
              _vm._t("display-html"),
              _vm.configurationNeededCount > 0
                ? _c(
                    "span",
                    {
                      ref: "configuration-needed",
                      staticClass: "unread-count configuration-needed",
                    },
                    [_vm._v("\n      !\n    ")]
                  )
                : _vm._e(),
              _vm.unreadCount > 0 && _vm.configurationNeededCount === 0
                ? _c(
                    "span",
                    { ref: "unread-count", staticClass: "unread-count" },
                    [_vm._v("\n      " + _vm._s(_vm.unreadCount) + "\n    ")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }