var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasExpiredCards || _vm.hasExpiredSoonCards
    ? _c(
        "div",
        {
          staticClass: "container",
          class: _vm.hasExpiredCards
            ? "expired-container"
            : "expired-soon-container",
        },
        [
          _c(
            "div",
            {
              staticClass: "icon-container mt-0",
              class: _vm.hasExpiredCards
                ? "expired-icon-container"
                : "expired-soon-icon-container",
            },
            [
              _c("expired-card-icon", {
                staticClass: "mt-3",
                attrs: { "is-expired": _vm.hasExpiredCards },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "message-container" }, [
            _c("div", { staticClass: "message typography pr-4 p-3" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.hasExpiredCards ? _vm.expiredMsg : _vm.expiredSoonMsg
                  ) +
                  "\n      "
              ),
              _c(
                "a",
                {
                  staticClass: "link typography",
                  attrs: { href: "#/account/payment-methods" },
                  on: {
                    click: function ($event) {
                      return _vm.setIsExpiredCardConversion(true)
                    },
                  },
                },
                [_vm._v("\n        Payment Methods\n      ")]
              ),
              _vm._v("\n      page to update payment information.\n    "),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }