var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return true /* TODO validToken */
    ? _c(
        "b-navbar-nav",
        {
          staticClass: "nav-cart-dropdown-component",
          attrs: { type: "dark", variant: "dark" },
        },
        [
          _c(
            "b-nav-item-dropdown",
            { attrs: { right: "" } },
            [
              _c("template", { slot: "button-content" }, [
                _c("div", { staticStyle: { float: "left" } }, [
                  _c("div", { staticClass: "nav-icon-label" }, [
                    _c(
                      "div",
                      { staticClass: "cart-items-icon" },
                      [
                        _c("fa-icon", {
                          staticClass: "cart-icon",
                          attrs: { icon: "shopping-cart" },
                        }),
                        _vm.cartItemsLength > 0
                          ? _c(
                              "div",
                              { ref: "cart-count", staticClass: "cart-count" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.cartItemsLength) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "b-dropdown-item",
                { staticClass: "mb-2 nav-cart-dropdown-item" },
                [
                  _c("nav-cart", {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }