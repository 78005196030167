var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "items-requiring-attention-modal",
      attrs: {
        id: "items-requiring-attention-modal",
        centered: "",
        size: "lg",
        "hide-footer": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _c("div", { staticClass: "cart-title card-title mt-0" }, [
          _vm._v("\n      Checkout\n\n      "),
          _vm.company
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _vm._v("\n        |\n        "),
                _vm.company
                  ? _c("span", { staticClass: "company-name" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.company.name) + "\n        "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.invoiceId
        ? _c("post-payment-items-requiring-attention", {
            attrs: {
              "company-id": _vm.company.id,
              "invoice-id": _vm.invoiceId,
            },
            on: { completed: _vm.completed },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }