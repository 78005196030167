var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasExpiredCards || _vm.hasExpiredSoonCards
    ? _c("b-img", {
        attrs: {
          src: _vm.hasExpiredCards ? _vm.expiredSrc : _vm.expiredSoonSrc,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }