<template>
  <b-nav-item-dropdown
    v-if="dropdown"
    class="ct-top-nav-item"
    v-bind="$attrs"
  >
    <template slot="button-content">
      <div class="nav-count-container">
        <slot name="display-html" />
        <span
          v-if="unreadCount > 0"
          ref="unread-count"
          class="unread-count"
        >
          {{ unreadCount }}
        </span>
      </div>
    </template>
    <slot name="dropdown-content" />
  </b-nav-item-dropdown>

  <b-nav-item
    v-else
    class="ct-top-nav-item"
    v-bind="$attrs"
  >
    <div class="nav-count-container">
      <slot name="display-html" />
      <span
        v-if="configurationNeededCount > 0"
        ref="configuration-needed"
        class="unread-count configuration-needed"
      >
        !
      </span>
      <span
        v-if="unreadCount > 0 && configurationNeededCount === 0"
        ref="unread-count"
        class="unread-count"
      >
        {{ unreadCount }}
      </span>
    </div>
  </b-nav-item>
</template>

<script>

export default {
  name: "CtTopNavItem",
  props: {
    displayHtml: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
    unreadCount: {
      type: Number,
      default: 0,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    configurationNeededCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      count: 0,
    }
  },
  watch: {
    unreadCount(newVal, _oldVal) {
      this.count = newVal
    },
  },
}
</script>

<style lang="scss">
  .ct-top-nav-item {
    transition: all ease .25s;

    position: relative;
    display: flex;
    align-items: center;
    border-left: 1px transparentize($ct-ui-color-4,0.2) solid;
    a, .nav-link {
      color: white !important;
      font-size: 0.9em;
      font-weight: 700;
      padding: 0 17px !important;
      line-height: 1.1em !important;
      white-space: nowrap;
    }
    &.navbar-brand {
      border-left: none !important;
    }
    &.active {
      background-color: #213C9C;
      &,
      & + li {
        border-radius: 4px;
        border-color: transparentize($ct-ui-color-4, 1);
      }
    }
    &:hover:not(.active) {
      background: #172686;
      border-radius: 4px;
      border-color: transparentize($ct-ui-color-4, 1);
      & + li {
        border-color: transparentize($ct-ui-color-4, 1);
      }
    }
    .nav-count-container {
      //position: relative;
      .unread-count {
        position: relative;
        left: 6px;
        top: -7px;
        font-size: 0.9em;
        line-height: 1.5em;
        text-align: center;
        font-weight: bolder;
        background: #f94b00;
        color: white;
        border-radius: 100px;
        height: 19px;
        min-width: 19px;
        padding: 0 3px 0 3px;
        display: inline-block;
      }
      .configuration-needed {
        background: #FF9800;
        color: white
      }
    }
  }
</style>
