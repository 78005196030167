<template>
  <b-navbar-nav
    v-if="true /* TODO validToken */"
    class="nav-cart-dropdown-component"
    type="dark"
    variant="dark"
  >
    <b-nav-item-dropdown right>
      <template slot="button-content">
        <div style="float: left">
          <div class="nav-icon-label">
            <div class="cart-items-icon">
              <fa-icon class="cart-icon" icon="shopping-cart" />
              <div v-if="cartItemsLength > 0"
                   ref="cart-count"
                   class="cart-count"
              >
                {{ cartItemsLength }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <b-dropdown-item class="mb-2 nav-cart-dropdown-item">
        <nav-cart @click.stop.prevent />
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>

  import { mapGetters } from 'vuex'
  import NavCart from './NavCart'
  import { setTimeout } from 'timers'

  export default {
    name: 'NavCartDropdown',

    components: {
      NavCart,
    },
    data() {
      return {
        countOfItemsInCart: 0,
      }
    },
    computed: {
      ...mapGetters('checkout', [
        'cartItems',
        'cartEmpty',
        'cartItemsLength',
      ]),
    },
    watch: {
      cartItems(newVal, oldVal) {
        if (oldVal.length < newVal.length) {
          this.animateCart()
        }
      },
    },
    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
      },
      async animateCart() {
        this.$refs['cart-count']?.classList?.add('bounce')
        await this.sleep(1000)
        this.$refs['cart-count']?.classList?.remove('bounce')
      },
    },
  }
</script>

<style lang="scss">
  .nav-cart-dropdown-component {
    .nav-cart-dropdown-item {
      pointer-events: none;
    }
    .dropdown-item {
      padding: 0;
    }
    .cart-items-icon {
      position: relative;
      width: 42px;
      top: 1px;
      .cart-icon {
        height: 34px;
        width: 34px;
      }
      .cart-count {
        border: 2px solid $ct-ui-background-2;
        position: absolute;
        left: 15px;
        bottom: 22px;
        font-size: 1em;
        line-height: 1.4em;
        text-align: center;
        font-weight: bolder;
        background: $ct-ui-nav-cart-background-color;
        color: $ct-ui-nav-cart-text-color;
        border-radius: 100px;
        height: 27px;
        padding: 0 7px;
      }
    }
  }

  .bounce {
    animation: bounce 1s;
  }

  @keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(2.8);
    }
    60% {
      transform: scale(1.7);
    }
  }
</style>
