<template>
  <div v-if="hasExpiredCards || hasExpiredSoonCards" class="container"
       :class="hasExpiredCards ? 'expired-container' : 'expired-soon-container'"
  >
    <div class="icon-container mt-0"
         :class="hasExpiredCards ? 'expired-icon-container' : 'expired-soon-icon-container'">
      <expired-card-icon :is-expired="hasExpiredCards" class="mt-3" />
    </div>
    <div class="message-container">
      <div class="message typography pr-4 p-3">
        {{hasExpiredCards ? expiredMsg : expiredSoonMsg}}
        <a class="link typography"
           href="#/account/payment-methods"
           @click="setIsExpiredCardConversion(true)">
          Payment Methods
        </a>
        page to update payment information.
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import ExpiredCardIcon from '@/components/Payments/ExpiredCards/ExpiredCardIcon'

export default {
  name: 'ExpiredCardsMessage',
  components: { ExpiredCardIcon },
  data() {
    return {
      expiredSrc: '/images/payments/message-alert-triangle.svg',
      expiredSoonSrc: '/images/payments/message-warning-triangle.svg',
      expiredMsg: 'A payment card associated with your account has expired. Visit the ',
      expiredSoonMsg: 'Your saved payment card is nearing expiration. Kindly visit the ',
    }
  },
  computed: {
    ...mapGetters('paymentMethods', ['hasExpiredCards', 'hasExpiredSoonCards']),
  },
  methods: {
    ...mapActions('paymentMethods', ['setIsExpiredCardConversion']),
  },
}
</script>

<style lang="scss" scoped>

  .container {
    display: flex;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 4px;
    border: 2px solid;
  }

  .expired-container {
    border-color: #DC3644;
    background-color: #F8D7DA;
  }

  .expired-soon-container {
    border-color: #FFC109;
    background-color: #FFE8A4;
  }

  .expired-icon-container {
    background-color: #DC3644;
  }

  .expired-soon-icon-container {
    background-color: #F6C344;
  }

  .icon-container {
    margin: 0 !important;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .message-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {

  }

  .typography {
    color: #404;
    font-family: "Open Sans";
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.05rem;
  }

  .link {
    font-weight: 700;
    text-decoration-line: underline;
  }
</style>
