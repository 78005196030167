var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    {
      staticClass: "ml-auto w-100 law-on-call-dropdown-component",
      attrs: { type: "dark", variant: "dark", right: "" },
    },
    [
      _c(
        "ct-top-nav-item",
        {
          staticClass: "law-on-call-nav-item",
          class: {
            active: _vm.$route.path.indexOf("law-on-call") > -1,
            disabled: _vm.activeLawOnCallServices.length < 1,
          },
          attrs: {
            to: "/law-on-call",
            disabled: _vm.activeLawOnCallServices.length < 1,
          },
        },
        [
          _c("template", { slot: "display-html" }, [
            _c("div", { staticClass: "nav-logo-container" }, [
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: require("@images/LawOnCall_logo_fi_icon.webp"),
                    type: "image/webp",
                  },
                }),
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: require("@images/LawOnCall_logo_fi_icon.png"),
                    alt: "Law On Call",
                  },
                }),
              ]),
              _vm._v("\n        Law On"),
              _c("br"),
              _vm._v("\n        Call\n      "),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "ct-top-nav-item",
        {
          staticClass: "law-on-call-nav-dropdown",
          class: { active: _vm.$route.path.indexOf("law-on-call") > -1 },
          attrs: { dropdown: "", "align-right": "" },
        },
        [
          _vm.activeLawOnCallServices.length > 0
            ? _c(
                "template",
                { slot: "dropdown-content" },
                [
                  _c("b-dropdown-text", [
                    _c(
                      "div",
                      { staticClass: "dropdown-container mb-2" },
                      [
                        _c("div", { staticClass: "logo-container" }, [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@images/LawOnCall_logo_fi.webp"),
                                type: "image/webp",
                              },
                            }),
                            _c("img", {
                              staticClass: "logo",
                              attrs: {
                                src: require("@images/LawOnCall_logo_fi.png"),
                                alt: "Law On Call",
                              },
                            }),
                          ]),
                        ]),
                        _c("hr"),
                        _c(
                          "p",
                          {
                            staticClass: "mb-4",
                            staticStyle: {
                              "text-align": "left",
                              "font-size": ".8em",
                            },
                          },
                          [
                            _vm._v(
                              "\n            You have active Law On Call service. For legal advice for your jurisdiction, reach out to our legal team at one of the numbers below.\n          "
                            ),
                          ]
                        ),
                        _c("hr"),
                        _vm._l(_vm.activeLawOnCallServices, function (service) {
                          return _c(
                            "div",
                            {
                              key: service.id,
                              staticClass: "active-law-on-call-service",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "state-province-region" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(service.stateProvinceRegion) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "phone-number" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(service.phoneNumber) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "template",
                { slot: "dropdown-content" },
                [
                  _c("b-dropdown-text", [
                    _c(
                      "div",
                      { staticClass: "dropdown-container mb-2 sign-up" },
                      [
                        _c("div", { staticClass: "logo-container" }, [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@images/LawOnCall_logo_fi.webp"),
                                type: "image/webp",
                              },
                            }),
                            _c("img", {
                              staticClass: "logo",
                              attrs: {
                                src: require("@images/LawOnCall_logo_fi.png"),
                                alt: "Law On Call",
                              },
                            }),
                          ]),
                        ]),
                        _c("hr"),
                        _c(
                          "div",
                          {
                            staticClass: "mb-4",
                            staticStyle: {
                              "text-align": "center",
                              "font-size": ".8em",
                            },
                          },
                          [
                            _c("p", { staticClass: "need-advice" }, [
                              _vm._v(
                                "\n              Looking for legal advice?\n            "
                              ),
                            ]),
                            _vm._v(
                              "\n            With our Law On Call service, a lawyer is just a phone call away.\n          "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { to: "/hire-us", variant: "primary" },
                          },
                          [
                            _vm._v(
                              "\n            Sign Up for $9/mo\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }