<template>
  <div v-if="unread || configurationNeededCount" class="d-flex flex-row justify-content-start align-items-center">
    <div class="fa-stack" style="position: relative; top: 2px; width: 2em;">
      <feather-icon :type="iconType" class="fa-stack-2x mr-3 " />
      <fa-icon
        v-if="configurationNeededCount > 0"
        class="fa-stack-1x float-right circle-configuration-needed mr-3 mb-3 pl-1"
        icon="circle"
        size="sm"
      />
      <fa-icon
        v-else-if="unread && configurationNeededCount === 0"
        class="fa-stack-1x float-right circle mr-3 mb-3 pl-1"
        icon="circle"
        size="sm"
      />
    </div>
    <span>
      {{ displayText }}
    </span>
  </div>
  <div v-else>
    <feather-icon :type="iconType" class="mr-2" />
    <span>
      {{ displayText }}
    </span>
  </div>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'

export default {
  name: "CtMenuItem",
  components: {
    FeatherIcon,
  },
  props: {
    displayText: {
      type: String,
      default: 'Menu Item',
    },
    iconType: {
      type: String,
      default: 'globe',
    },
    unread: {
      type: [Number, Boolean],
      default: false,
    },
    configurationNeededCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
  .circle {
    color: #dc3545;
    right: -.5rem;
  }

  .circle-configuration-needed {
    color: #FF9800;
    right: -.5rem;
  }
</style>
